import React, { useState } from 'react';

function Candidates() {
  const [showExpressInterest, setShowExpressInterest] = useState(false);

  const handleToggleForm = () => {
    setShowExpressInterest((prev) => !prev);
  };

  return (
    <div className="portal">
      {!showExpressInterest ? (
        <>
          <h2>Candidate Portal</h2>
          <p>
          Coming soon.
        </p>
          {/* <form>
            <input type="text" placeholder="Username" required />
            <input type="password" placeholder="Password" required />
            <button type="submit">Login</button>
            <a href="#" className="interest-link" onClick={handleToggleForm}>
              Express your interest
            </a>
          </form> */}
        </>
      ) : (
        <>
          <h2>Express Your Interest</h2>
          <p>
          Coming soon.
        </p>
          {/* <form>
            <input type="text" placeholder="Full Legal Name" required />
            <input type="tel" placeholder="Contact Number" required />
            <input type="email" placeholder="Contact Email" required />
            <input type="text" placeholder="Preferred Location" required />
            <input type="text" placeholder="Citizenship Status" required />
            <input type="text" placeholder="Clearance Status" required />
            <input type="text" placeholder="Engagement Type" />
            <input type="file" />
            <textarea placeholder="Referee Details"></textarea>
            <input type="text" placeholder="Notice Period" />
            <input type="number" placeholder="Hourly Rate" />
            <textarea placeholder="Key Skills"></textarea>
            <button type="submit">Submit</button>
            <a href="#" className="interest-link" onClick={handleToggleForm}>
              Back to login
            </a>
          </form> */}
        </>
      )}
    </div>
  );
}

export default Candidates;
