function AboutUs() {
    return (
      <div>
        <section className="vision section">
          <h2>Our Vision</h2>
          <p>
          To build a future where organisations have seamless access to the best talent, and every professional finds meaningful work.
          </p>
        </section>
  
        <section className="mission section">
          <h2>Our Mission</h2>
          <p>
          At appsytec, we understand the unique challenges and
          opportunities of the local market. We connect our candidates
          to clients with a blend of professionalism and dedication,
          ensuring a seamless integration and support throughout their
          journey.
          </p>
        </section>
      </div>
    );
  }
  
  export default AboutUs;
  