import React from 'react';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();

  return (
    <div>
      {/* Hero Section */}
      <section className="hero section">
        <h1>Connecting Talent with Opportunity</h1>
        <h3>Your trusted partner for streamlined labour hire and job placements.</h3>
        <button onClick={() => navigate('/contact-us')}>Get in touch</button>
      </section>

      {/* About Section */}
      <section className="about">
        <div className="about-content">
          <h2>Our Company</h2>
          <p>
            With a commitment to excellence and innovation, <strong>appsytec</strong>  is
            your trusted partner for all your recruitment and labour hire
            needs, delivering results that exceed expectations.
          </p>
        </div>
        <div className="about-image">
          <img src="/images/about-us.jpg" alt="About Us" />
        </div>
      </section>

      {/* Services Section */}
      <section className="services section">
        <h2>Our Services</h2>
        <div className="service-container">
          <div className="service-item">
            <img src="/icons/parliament-small.svg" alt="parliament house" />
            <h3>For Public Servants</h3>
            <p>Helping you spend less time vetting candidates so you can get on with your work, making a difference to the Australian community.</p>
          </div>
          <div className="service-item">
            <img src="/icons/applicant.svg" alt="applicant" />
            <h3>For Candidates</h3>
            <p>Helping to find you a role and environment where you can demonstrate the unique qualities and strengths that make you excel.</p>
          </div>
          <div className="service-item">
            <img src="/icons/business.svg" alt="business" />
            <h3>For Businesses</h3>
            <p>Helping you find the right talent so you can get on with your business and maximise profits.</p>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      {/* <section className="testimonials section">
        <h2>Testimonials</h2>
        <div className="testimonial-list">
          <div className="testimonial-item">
            <img src="/images/testimonial1.jpg" alt="testimonial image 1" />
            <h3>Mela Stark, Freelance Architect</h3>
            <p>"Testimonials are short quotes from people who love your brand."</p>
          </div>
          <div className="testimonial-item">
            <img src="/images/testimonial2.jpg" alt="testimonial image 2" />
            <h3>Finn Styles, Software Engineer</h3>
            <p>"It's a great way to convince customers to try your services."</p>
          </div>
          <div className="testimonial-item">
            <img src="/images/testimonial3.jpg" alt="testimonial image 3" />
            <h3>Imani Olowe, Business Owner</h3>
            <p>"A great way to convince customers to try your services."</p>
          </div>
        </div>
      </section> */}

      {/* Closing Section */}
      <section className="closing section">
        <div className="closing-content">
          <img src="/icons/delegate.svg" alt="closing Icon" className="closing-icon" />
          <p>Leave your recruitment worries to us, so you can focus on what's important to you.</p>
        </div>
      </section>

      {/* Contact Us Section */}
<section className="contact-section">
  <h2>Contact Us</h2>
  <div className="contact-details">
    <div className="contact-item">
      <h3>Email Address</h3>
      <p>hello@appsytec.com</p>
    </div>

    <div className="contact-item">
      <h3>Contact Form</h3>
      <a href="/contact-us" className="contact-link">Visit our contact page</a>
    </div>

    <div className="contact-item">
      <h3>Socials</h3>
      <div className="social-links">
        <a href="https://www.linkedin.com/company/appsytec/" target="_blank" rel="noopener noreferrer">
          <img src="/icons/linkedin-96.svg" alt="LinkedIn" className="social-icon" />
        </a>
        <a href="https://x.com/appsytec" target="_blank" rel="noopener noreferrer">
          <img src="/icons/twitterx-96.svg" alt="Twitter/X" className="social-icon" />
        </a>
      </div>
    </div>

    {/* Optional Phone Number Section */}
    {/* <div className="contact-item">
      <h3>Phone Number</h3>
      <p>(+61) 423 081 845</p>
    </div> */}
  </div>
</section>


      {/* Footer Section */}
      <footer className="footer">
        <div className="footer-content">
          <p>appsytec © 2024 All Rights Reserved | <a href="#">Site Map</a> | <a href="#">Privacy Policy</a></p>
        </div>
      </footer>
    </div>
  );
}

export default Home;
