// import { Link } from 'react-router-dom';

// function Navbar() {
//   return (
//     <nav className="navbar">
//       <img src="/appsytec-logo.png" alt="Appsytec Logo" height="40" />
//       <div>
//         <Link to="/">Home</Link>
//         <Link to="/about-us">About Us</Link>
//         <Link to="/contact-us">Contact Us</Link>
//         <Link to="/clients">Clients</Link>
//         <Link to="/candidates">Candidates</Link>
//       </div>
//     </nav>
//   );
// }

// export default Navbar;

import React, { useState } from 'react';
import './Navbar.css'; // Assuming your CSS file is named Navbar.css

function Navbar() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <img src="/appsytec-logo.png" alt="Appsytec Logo" className="navbar-logo" />
      <div className={`navbar-links ${isMobileMenuOpen ? 'active' : ''}`}>
        <a href="/" onClick={() => setMobileMenuOpen(false)}>Home</a>
        <a href="/about-us" onClick={() => setMobileMenuOpen(false)}>About Us</a>
        <a href="/contact-us" onClick={() => setMobileMenuOpen(false)}>Contact Us</a>
        <a href="/clients" onClick={() => setMobileMenuOpen(false)}>Clients</a>
        <a href="/candidates" onClick={() => setMobileMenuOpen(false)}>Candidates</a>
      </div>
      <div className="hamburger" onClick={toggleMobileMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </nav>
  );
}

export default Navbar;




