import React, { useState } from 'react';
import axios from 'axios';

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [statusMessage, setStatusMessage] = useState(''); // Define state for status message
  const [messageType, setMessageType] = useState(''); // Define state for message type ('success' or 'error')

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const response = await axios.post('/api/sendEmail', formData);
      if (response.status === 200) {
        setStatusMessage('Contact form submitted successfully! We will be in touch shortly.');
        setMessageType('success');
        setFormData({
          name: '',
          email: '',
          message: '',
        });
      } else {
        setStatusMessage('Failed to submit. Please try again later.');
        setMessageType('error');
      }
    } catch (error) {
      setStatusMessage('An error occurred while sending the email. Please contact us at contact@appsytec.com instead.');
      setMessageType('error');
    }
  };

  return (
    <div className="contact-section">
      <h2>Contact Us</h2>
      <form className="contact-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleInputChange}
          required
        /><br />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleInputChange}
          required
        /><br />
        <textarea
          name="message"
          placeholder="Your Message"
          value={formData.message}
          onChange={handleInputChange}
          required
        ></textarea><br />
        <button type="submit">Send</button>
      </form>
      {statusMessage && (
        <p className={`status-message ${messageType}`}>{statusMessage}</p>
      )}
    </div>
  );
}

export default ContactUs;
