function Clients() {
    return (
      <div className="portal">
        <h2>Client Portal</h2>
        <p>
          Coming soon.
        </p>
        {/* <form>
          <input type="text" placeholder="Username" required />
          <input type="password" placeholder="Password" required />
          <button type="submit">Login</button>
        </form> */}
      </div>
    );
  }
  
  export default Clients;
  